import { useState } from 'react'
import { Link } from 'gatsby'
import './styles.scss'

import Button from '../Button'
import { ArrowIconUp, ArrowIconDown } from './SidebarServiceArrow'

const SidebarDropDown = () => {
  const [openTopBar, setOpenTopBar] = useState<boolean>(false)
  const [active, setActive] = useState<boolean>(false)

  const handleClickDropDown = () => {
    setOpenTopBar(!openTopBar)
    setActive(!active)
  }

  return (
    <>
      <div className="services-sidebar menu-dropdown w-full ">
        <Button
          aria-label="Abrir ou fechar menu"
          className="button-dropdown"
          onClick={handleClickDropDown}
        >
          {' '}
          Ver todos os serviços
          {active ? (
            /* Arrow Up */
            <ArrowIconUp />
          ) : (
            /* Arrow Down */
            <ArrowIconDown />
          )}
        </Button>

        {openTopBar && (
          <div className="services-sidebar__list_drop">
            <Link
              className="services-sidebar__list-item"
              to="https://www.decathlonpro.com.br/?utm_source=Site_B2C&utm_medium=footer&utm_campaign=footer"
              target="_blank"
              title="Atendimento Corporativo"
              rel="noreferrer"
            >
              Atendimento Corporativo
            </Link>

            <Link
              className="services-sidebar__list-item"
              to="/servicos/cartao-presente"
              title="Cartão Presente"
            >
              Cartão Presente
            </Link>

            <Link
              className="services-sidebar__list-item"
              to="/servicos/cupom-de-desconto"
              title="Cupons de Desconto"
            >
              Cupons de Desconto
            </Link>

            <Link
              className="services-sidebar__list-item"
              to="/servicos/formas-de-pagamento"
              title="Formas de Pagamento"
            >
              Formas de pagamento
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/garantia-vitalicia"
              title="Garantia Vitalicia"
            >
              Garantia Vitalícia
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/politica-de-entrega"
              title="Politica de entrega"
            >
              Política de Entrega
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/politica-de-privacidade"
              title="Politica de Privacidade"
            >
              Política de Privacidade
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/regras-de-promocoes"
              title="Regras de promoçoes"
            >
              Regras de Promoções
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/trocas-e-devolucoes"
              title="Trocas e devolucoes"
            >
              Trocas e devoluções
            </Link>
            <Link
              className="services-sidebar__list-item"
              to="/servicos/vale-troca"
              title="Vale Troca"
            >
              Vale troca
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

export default SidebarDropDown
