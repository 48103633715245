interface IconProps {
  size?: {
    width: number
    height: number
  }
  viewBox?: string
  color?: string
}
export const ArrowIconUp = ({
  size = { width: 13, height: 8 },
  viewBox = '0 0 16 16',
  color = '#fff',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox={viewBox}
    width={size.width}
    height={size.height}
    color={color}
    style={{ transform: 'rotate(90deg)' }}
  >
    <path
      d="M11 1L4 8L11 15"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      fill="none"
    />
  </svg>
)
export const ArrowIconDown = ({
  size = { width: 13, height: 8 },
  viewBox = '0 0 16 16',
  color = '#fff',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox={viewBox}
    width={size.width}
    height={size.height}
    color={color}
    style={{ transform: 'rotate(270deg)' }}
  >
    <path
      d="M11 1L4 8L11 15"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      fill="none"
    />
  </svg>
)
export const ArrowIconRight = ({
  size = { width: 13, height: 8 },
  viewBox = '0 0 16 16',
  color = '#fff',
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox={viewBox}
    width={size.width}
    height={size.height}
    color={color}
    style={{ transform: 'rotate(180deg)' }}
  >
    <path
      d="M11 1L4 8L11 15"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
      fill="none"
    />
  </svg>
)
