import { Link } from 'gatsby'
import './styles.scss'

import SidebarDropdown from './SidebarDropdown'

const SidebarService = () => {
  return (
    <>
      <div className="services-sidebar">
        <SidebarDropdown />
        <div className="services-sidebar__header">
          <h1 className="sidebar-header__title">Serviços</h1>
        </div>

        <div className="services-sidebar__list">
          <Link
            className="services-sidebar__list-item"
            to="https://www.decathlonpro.com.br/?utm_source=Site_B2C&utm_medium=footer&utm_campaign=footer"
            target="_blank"
            title="Atendimento Corporativo"
            rel="noreferrer"
          >
            Atendimento Corporativo
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/cartao-presente"
            target="_blank"
            title="Cartão Presente"
          >
            Cartão Presente
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/cupom-de-desconto"
            target="_blank"
            title="Cupons de Desconto"
          >
            Cupons de Desconto
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/circula/produtos"
            target="_blank"
            title="Decathlon Circula"
          >
            Decathlon Circula
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/formas-de-pagamento"
            target="_blank"
            title="Formas de Pagamento"
          >
            Formas de pagamento
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/garantia-vitalicia"
            target="_blank"
            title=" Garantia Vitalícia"
          >
            Garantia Vitalícia
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/recall"
            target="_blank"
            title="Recall"
          >
            Recall de Produtos
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/politica-de-entrega"
            target="_blank"
            title="Política de Entrega"
          >
            Política de Entrega
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/politica-de-privacidade"
            target="_blank"
            title="Política de Privacidade"
          >
            Política de Privacidade
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/regras-de-promocoes"
            target="_blank"
            title=" Regras de Promoções"
          >
            Regras de Promoções
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/trocas-e-devolucoes"
            target="_blank"
            title=" Trocas e devoluções"
          >
            Trocas e devoluções
          </Link>
          <Link
            className="services-sidebar__list-item"
            to="/servicos/vale-troca"
            target="_blank"
            title="Vale troca"
          >
            Vale troca
          </Link>
        </div>

        <div className="services-sidebar__footer">
          <div className="sidebar-footer__text">
            Esportista satisfeito é a nossa missão!
          </div>
        </div>
      </div>
    </>
  )
}

export default SidebarService
